<template>
	<div>
		<div class='a-booking a-content a-content-bigger-box a-faq a-faq-single'>
			<div class='app-container app-container-fluid a-container'>
				<div class="a-faq-breadcrumbs tablet-hide">
					<ul class='a-breadcrumbs-list'>
						<li class='a-breadcrumbs-item active'>
							<router-link :to='$i18nRoute({name: "faq-hc"})' class='a-breadcrumbs-link link'>FAQ</router-link>
							<v-icon icon='arrow-down' size='9' class='a-breadcrumbs-icon' />
						</li>
						<li class='a-breadcrumbs-item active'>
							<router-link :to='$i18nRoute({name: "faq-category", params: {categoryId: this.$route.params.categoryId}})' class='a-breadcrumbs-link link'>{{item.category}}</router-link>
							<v-icon icon='arrow-down' size='9' class='a-breadcrumbs-icon' v-if='!loading' />
						</li>
						<li class='a-breadcrumbs-item' v-if='!loading'>
							<span class='a-breadcrumbs-link'>{{item.question}}</span>
						</li>
					</ul>
				</div>
				<div class='a-booking-container'>
					<div class='a-booking-content' style='max-width: 850px'>
						<div class='a-booking-box'>
							<div class="a-faq-breadcrumbs tablet-show">
								<ul class='a-breadcrumbs-list'>
									<li class='a-breadcrumbs-item active'>
										<router-link :to='$i18nRoute({name: "faq-hc"})' class='a-breadcrumbs-link link'>FAQ</router-link>
										<v-icon icon='arrow-down' size='9' class='a-breadcrumbs-icon' />
									</li>
									<li class='a-breadcrumbs-item active'>
										<router-link :to='$i18nRoute({name: "faq-category", params: {categoryId: this.$route.params.categoryId}})' class='a-breadcrumbs-link link'>{{item.category}}</router-link>
										<v-icon icon='arrow-down' size='9' class='a-breadcrumbs-icon' v-if='!loading' />
									</li>
									<li class='a-breadcrumbs-item' v-if='!loading'>
										<span class='a-breadcrumbs-link'>{{item.question}}</span>
									</li>
								</ul>
							</div>
							<p class='a-content-title'>{{item.question}}</p>

							<div class='a-content-block' v-html='item.text'></div>
						</div>
					</div>
					<div class="a-booking-banner"></div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import { getFaq } from '@/services/request';

	export default {
		data: () => ({
			item: {},
			loading: false
		}),
		created() {
			this.getFaq();
		},
		methods: {
			getFaq() {
				this.loading = true;
				getFaq(this.$route.params.id).then(res => this.item = res.data).finally(() => this.loading = false)
			}
		}
	}
</script>
